import ApiService from "../ApiService";

const providerService = {
    getProviders() {
        return ApiService.get(`/provider`);
    },

    createProvider(post = true, items, id) {
        if (post) {
            return ApiService.post(`provider/v1/`, items);
        }
        return ApiService.put(`provider/v1/?id=${id}`, items);
    }
};

export default providerService;