import providerService from "../../../services/provider/ProviderService";

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: ""
        },
        {
          text: "Nit",
          sortable: false,
          value: ""
        },
        {
          text: "Estado",
          sortable: false,
          value: ""
        },
        {
          text: "Url",
          sortable: false,
          value: ""
        }
      ],
      itemsData: []
    };
  },
  props: {},
  watch: {},
  mounted() {
    this.getProviders();
  },
  methods: {
    onRowClick(item) {
      this.$emit("row-click", item);
    },
    getProviders() {
      providerService.getProviders().then(response => {
        this.itemsData = response.data;
      });
    }
  }
};
